<template>
  <page>
    <div slot="headerLeft">
      <el-select
        clearable
        v-model="params.warningType"
        :placeholder="$t('customerStatistical.warningType')"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        v-if="btnShow && btnShow.config"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        className="configIcon"
        icon="iconsetting"
        >{{ $t('customerStatistical.warningConfig') }}</c-button
      >
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column prop="time" :label="$t('customerStatistical.warningTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.warningTime) }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('customerStatistical.warningType')">
          <template slot-scope="{ row }">
            {{
              row.warningType === 1
                ? $t('deviceList.cpuTemperature')
                : row.warningType === 2
                ? $t('customerHome.runnMemory')
                : row.warningType === 3
                ? $t('customerHome.storageCapacity')
                : row.warningType === 4
                ? $t('deviceList.printer')
                : row.warningType === 5
                ? $t('customerStatistical.subScreenWarning')
                : row.warningType === 6
                ? $t('deviceList.scanningDevice')
                : row.warningType === 7
                ? $t('deviceList.camera')
                : $t('deviceList.electronicScales')
            }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="sn" :label="$t('deviceList.sn')" />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="warningContent"
          :label="$t('customerStatistical.warningContent')"
        >
          <template slot-scope="{ row }">
            {{
              row.warningType === 1
                ? $t('customerStatistical.warningType1', { nums: row.nums })
                : row.warningType === 2
                ? $t('customerStatistical.warningType2', { nums: row.nums })
                : row.warningType === 3
                ? $t('customerStatistical.warningType3', { nums: row.nums })
                : row.warningType === 4
                ? $t('customerStatistical.warningType4', { nums: row.nums })
                : row.warningType === 5
                ? $t('customerStatistical.warningType5')
                : row.warningType === 6
                ? $t('customerStatistical.warningType6')
                : row.warningType === 7
                ? $t('customerStatistical.warningType7')
                : row.warningType === 8
                ? $t('customerStatistical.warningType8')
                : $t('customerStatistical.warningTypeOther')
            }}</template
          >
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <config-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import configModal from './components/configModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    configModal
  },
  data() {
    return {
      options: [
        {
          label: this.$t('deviceList.cpuTemperature'),
          value: 1
        },
        {
          label: this.$t('customerHome.runnMemory'),
          value: 2
        },
        {
          label: this.$t('customerHome.storageCapacity'),
          value: 3
        },
        {
          label: this.$t('deviceList.printer'),
          value: 4
        },
        {
          label: this.$t('customerStatistical.subScreenWarning'),
          value: 5
        },
        {
          label: this.$t('deviceList.scanningDevice'),
          value: 6
        },
        {
          label: this.$t('deviceList.camera'),
          value: 7
        },
        {
          label: this.$t('deviceList.electronicScales'),
          value: 8
        }
      ],
      loading: false,
      visible: false,
      currentItem: null,
      tableData: []
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      const data = this.params
      data.system = 2
      try {
        const res = await this.$api.systemManagement.warnInfoList(data)
        if (res.result) {
          let reg = /[0-9]+([.]{1}[0-9]+){0,1}/
          this.tableData = (res.result.list || []).map(item => {
            if (item.warningContent.match(reg)) {
              item.nums = item.warningContent.match(reg)[0] ? item.warningContent.match(reg)[0] : ''
            } else {
              item.nums = ''
            }
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <page>
    <div slot="headerLeft">
      <el-input
        clearable
        :placeholder="$t('sourceManagement.resourceTitle')"
        v-model.trim="params.resourceDesc"
      />

      <el-select v-model="params.system" :placeholder="$t('sourceManagement.system')">
        <el-option :label="$t('loginPage.sdyPlatform')" :value="1" />
        <el-option :label="$t('loginPage.customerPlatform')" :value="2" />
      </el-select>

      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <!-- <el-button type="danger" plain @click="delHandler(true)">{{ $t('delete') }}</el-button> -->
    </div>
    <div slot="headerRight">
      <c-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
          parentId = ''
        "
        icon="iconxinzeng"
        >{{ $t('newadd') }}</c-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
        row-key="id"
        :tree-props="{ children: 'children' }"
      >
        <!-- <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" /> -->
        <el-table-column
          sortable
          show-overflow-tooltip
          prop="resourceDesc"
          :formatter="formatterResourceDesc"
          :label="$t('sourceManagement.resourceTitle')"
          min-width="240"
        />
        <el-table-column width="120" :label="$t('spaceDetails.resourceType')">
          <template slot-scope="{ row }">
            <span :style="{ color: row.type === 1 ? '#ef2b23' : '#ebb563' }">{{
              row.type === 1 ? $t('sourceManagement.menu') : $t('sourceManagement.button')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          show-overflow-tooltip
          prop="system"
          :label="$t('sourceManagement.system')"
        >
          <template slot-scope="{ row }">
            <span>{{
              Number(row.system) === 1
                ? $t('loginPage.sdyPlatform')
                : $t('loginPage.customerPlatform')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip prop="resourceId" label="ID" />

        <el-table-column width="80" :label="$t('activeDevice.state')">
          <template slot-scope="{ row }">
            <span :style="{ color: row.status === 0 ? '#ef2b23' : '#F56C6C' }">{{
              row.status === 0 ? $t('sourceManagement.enable') : $t('sourceManagement.stop')
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          show-overflow-tooltip
          prop="resourceName"
          :label="$t('spaceDetails.resourceName')"
          min-width="200"
        />

        <el-table-column
          show-overflow-tooltip
          prop="permissionName"
          :formatter="formatterPermissionName"
          :label="$t('sourceManagement.authorizationName')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="permissionUrl"
          :label="$t('sourceManagement.authorizationURL')"
        />

        <el-table-column width="220" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.edit"
                type="primary"
                size="small"
                @click="editHandler(row)"
                >{{ $t('edit') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.addChild && row.type === 1"
                type="primary"
                size="small"
                @click.stop="
                  visible = true
                  currentItem = null
                  parentSystem = row.system
                  parentId = row.resourceId
                "
                >{{ $t('sourceManagement.addSubResources') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.delete"
                type="danger"
                size="small"
                :disabled="row.resourceName === 'systemManagement'"
                @click="delHandler(false, row.resourceId)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-source-modal
      @getData="replaceData"
      :parentId="parentId"
      :parentSystem="parentSystem"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddSourceModal from './components/AddSourceModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddSourceModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      parentId: '',
      parentSystem: 1,
      currentItem: {},
      params: {
        system: 2
      },
      multipleSelection: [],
      tableData: []
    }
  },
  created() {},
  methods: {
    toTree(tarArray = []) {
      var obj = {}
      tarArray = tarArray.sort((a, b) => b.resourceId - a.resourceId)
      tarArray.map((item, index) => {
        obj[item.resourceId] = item
      })
      var newArr = []
      for (var i = 0; i < tarArray.length; i++) {
        var item = tarArray[i]
        var parent = obj[item.parentId] || ''
        if (parent) {
          if (parent.children) {
            parent.children.push({ ...item })
          } else {
            if (!parent.children) {
              parent.children = []
            }
            parent.children.push({ ...item })
          }
        } else {
          newArr.push({ ...item })
        }
      }
      return newArr
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (!this.params.system) {
        delete this.params.system
      }
      this.params.pageSize = 2000

      try {
        const res = await this.$api.systemManagement.resourceList(this.params)

        if (res.result) {
          this.tableData = this.toTree(res.result.list || [])

          // console.log(this.tableData)
        } else {
          this.tableData = []
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.resourceId)
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },
    delHandler(delMore, id) {
      if (delMore && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      this.$confirm(this.$t('sourceManagement.deleteTesourcesTips'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        customClass: 'importantDel'
      })
        .then(async () => {
          this.loading = true

          try {
            await this.$api.systemManagement.resourceDelete(delMore ? this.multipleSelection : [id])
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    formatterResourceDesc(row) {
      if (row.resourceDesc) {
        let str = `basicsData["${row.resourceDesc}"]`
        return this.$t(str)
      }
      return ''
    },
    formatterPermissionName(row) {
      if (row.permissionName) {
        let str = `permissionName["${row.permissionName}"]`
        return this.$t(str)
      }
      return ''
    }
  }
}
</script>

<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('serviceManagement.customerName')"
        v-model.trim="params.name"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button v-show="multipleSelection.length" type="danger" plain @click="delHandler(true)">{{
        $t('delete')
      }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="iconxinzeng"
        >{{ $t('newadd') }}</c-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column show-overflow-tooltip prop="spId" :label="$t('ID')" />
        <el-table-column
          show-overflow-tooltip
          prop="name"
          :label="$t('serviceManagement.customerName')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="contactName"
          :label="$t('serviceManagement.contacts')"
        />
        <el-table-column
          min-width="120"
          show-overflow-tooltip
          prop="phone"
          :label="$t('serviceManagement.phone')"
        />

        <el-table-column width="100" :label="$t('activeDevice.state')">
          <template slot-scope="{ row }">
            <el-switch v-model="row.status" @change="statusUpdate(row)" />
          </template>
        </el-table-column>

        <el-table-column
          min-width="160"
          show-overflow-tooltip
          :label="$t('deviceList.createdTime')"
        >
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="100"
          show-overflow-tooltip
          :label="$t('serviceManagement.deadline')"
        >
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.expirationDate, 'YYYY-MM-DD') }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip :label="$t('serviceManagement.termOfValidity')">
          <template slot-scope="{ row }">
            <span>{{ row.validDateStr }}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip :label="$t('serviceManagement.deviceUpperLimit')">
          <template slot-scope="{ row }">
            <span>{{
              +row.deviceMax > 0
                ? row.deviceMax + $t('adminHomePage.tower')
                : $t('serviceManagement.unlimited')
            }}</span>
          </template>
        </el-table-column>

        <el-table-column width="200" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.relate"
                type="text"
               
                @click="editHandler(row)"
                >{{ $t('serviceManagement.associatedCustomers') }}</el-button
              >

              <!-- <el-button
                v-if="btnShow && btnShow.validDate"
                type="text"
               
                @click="editHandler(row, 'validDate')"
                >有效期</el-button
              > -->

              <el-button
                v-if="btnShow && btnShow.edit"
                type="text"
               
                @click="editHandler(row, 'edit')"
                >{{ $t('edit') }}</el-button
              >

              <el-button
                v-if="btnShow && btnShow.delete"
                type="text"
               
                @click="delHandler(false, row.spId)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-service-modal
      @getData="getData"
      :currentItem="currentItem"
      :currentSpNames="currentSpNames"
      :visible.sync="visible"
      v-if="visible"
    />

    <relate-modal :currentItem="currentItem" :visible.sync="visibleRelate" v-if="visibleRelate" />
    <valid-date-modal
      :currentItem="currentItem"
      :visible.sync="visibleValidDate"
      v-if="visibleValidDate"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddServiceModal from './components/AddServiceModal'
import RelateModal from './components/RelateModal'
import ValidDateModal from './components/ValidDateModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddServiceModal,
    RelateModal,
    ValidDateModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      visibleRelate: false,
      visibleValidDate: false,
      currentItem: {},
      tableData: [],
      currentSpNames: [],
      multipleSelection: [],
      validDateObj: {
        0: this.$t('loginPage.longTerm'),
        1: '7' + this.$t('loginPage.day'),
        2: '15' + this.$t('loginPage.day'),
        3: '30' + this.$t('loginPage.day'),
        4: '90' + this.$t('loginPage.day'),
        5: '1' + this.$t('loginPage.year')
      }
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.systemManagement.spList(this.params)

        if (res.result) {
          this.tableData = res.result.list.map(item => {
            let vStr = ''

            if (!item.expirationDate) {
              vStr = this.$t('loginPage.longTerm')
            } else {
              const timeStamp = Math.ceil(
                (+new Date(item.expirationDate) - Date.now()) / (24 * 3600 * 1000)
              )

              vStr =
                timeStamp > 0 ? timeStamp + this.$t('loginPage.day') : this.$t('loginPage.expired')
            }

            item.validDateStr = vStr

            item.status = !item.status
            return item
          })

          this.currentSpNames = res.result.list.map(item => item.name)

          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.spId)
    },
    editHandler(row, type) {
      this.currentItem = row

      if (type === 'validDate') {
        this.visibleValidDate = true
      } else if (type === 'edit') {
        this.visible = true
      } else {
        this.visibleRelate = true
      }
    },

    delHandler(delMore, spId) {
      this.$confirm(
        delMore
          ? this.$t('serviceManagement.deleteSelectedCustomer')
          : this.$t('serviceManagement.deleteThisCustomer'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          this.loading = true

          try {
            await this.$api.systemManagement.spDelete(delMore ? this.multipleSelection : [spId])
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async statusUpdate(row) {
      this.loading = true

      try {
        await this.$api.systemManagement.spUpdate({ spId: row.spId, status: row.status ? 0 : 1 })
      } catch (e) {
        row.status = !row.status
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>

<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="$t('serviceManagement.associatedCustomers')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="relate-modal"
  >
    <el-form :model="form" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('serviceManagement.customerName')">
        <el-input readonly v-model.trim="form.name" />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.customerID')">
        <el-input readonly v-model.trim="form.spId" />
      </el-form-item>
    </el-form>
    <p class="tips">
      {{ $t('serviceManagement.noteAssociatedCustomers') }}
    </p>
    <span class="btnWrap">
      <c-button size="mini" type="primary" @click="visibleAdd = true" icon="iconxinzeng">{{
        $t('serviceManagement.addAssociatedCustomers')
      }}</c-button>
    </span>

    <el-table size="mini" :data="tableData" border v-loading="loading" :max-height="300">
      <el-table-column
        show-overflow-tooltip
        prop="relationSpId"
        :label="$t('serviceManagement.customerID')"
      />
      <el-table-column
        show-overflow-tooltip
        prop="spName"
        :label="$t('serviceManagement.customerName')"
      />
      <el-table-column show-overflow-tooltip :label="$t('serviceManagement.associatedTime')">
        <template slot-scope="{ row }">
          {{ $utils.formatTime(row.relationTime) }}
        </template>
      </el-table-column>

      <el-table-column width="150" :label="$t('operation')">
        <template slot-scope="{ row }">
          <div class="operateRow">
            <el-button type="text" @click="delHandler(row.id)">{{
              $t('serviceManagement.deleteAssociation')
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <add-relate-modal
      @getData="getData"
      :spId="form.spId"
      :visible.sync="visibleAdd"
      v-if="visibleAdd"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import AddRelateModal from './AddRelateModal.vue'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['currentSpNames'],
  data() {
    return {
      visibleAdd: false,
      loading: false,
      form: {
        spId: '',
        name: ''
      },
      total: 0,

      tableData: []
    }
  },
  components: {
    AddRelateModal
  },
  created() {
    Object.keys(this.form).forEach(key => {
      this.form[key] = this.currentItem[key]
    })
    this.getData()
  },
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.systemManagement.spRelationList({
          spId: this.currentItem.spId
        })

        if (res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    delHandler(id) {
      this.$confirm(this.$t('serviceManagement.deleteThisCustomer'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            await this.$api.systemManagement.spRelationDel({ id })
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.currentSpNames.includes(data.name) && this.isCreated) {
            this.$message.error(this.$t('serviceManagement.changeCustomerName'))
            return
          }

          this.loading = true

          if (!this.isCreated) {
            data.spId = this.currentItem.spId
          }

          try {
            this.isCreated
              ? await this.$api.systemManagement.spAdd(data)
              : await this.$api.systemManagement.spUpdate(data)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.relate-modal {
  ::v-deep .el-form {
    width: 400px;
    margin: 0 auto;
  }

  .btnWrap,
  .tips {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .operateRow {
    .el-button {
      padding: 5px 6px;
    }
  }
}
</style>
